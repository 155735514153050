import React from 'react'

export default function StudyOptions() {
    return (

        <>
            <div className='p-3 m-1 mt-2 rounded ' id='options'>
                <b className='p-3 opacity-75'>Important Links</b><br />
                <br />


                <div class="row mt-2">
                    <a class="col-4 btn" onClick={()=>{showWebContent('https://ignouadmission.samarth.edu.in/')}}  >
                        <div class="p-3 border rounded bg-white shadow text-center h6">
                            <span class="material-symbols-outlined">
                                notifications_active
                            </span> <br />
                            Latest <br /> Notice
                        </div>
                    </a>
                    <a class="col-4 btn" onClick={()=>{showWebContent('https://gradecard.ignou.ac.in/gradecard/')}}  >
                        <div class="p-3 border rounded bg-white shadow text-center h6">
                            <span class="material-symbols-outlined">
                                description
                            </span><br />
                            Grade <br /> Card
                        </div>
                    </a>
                    <a class="col-4 btn" onClick={()=>{showWebContent('http://ignou.ac.in/userfiles/Common-Prospectus-English.pdf')}} >
                        <div class="p-3 border rounded bg-white shadow text-center h6">
                            <span class="material-symbols-outlined">
                                check_box
                            </span><br />
                            Admit <br /> Card
                        </div>
                    </a>
                </div>

                <div className='row mt-2' >
                    <a class="col-4 btn" onClick={()=>{showWebContent('http://ignou.ac.in/userfiles/Common-Prospectus-English.pdf')}}  >
                        <div class="p-3 border rounded bg-white shadow text-center h6">
                            <span class="material-symbols-outlined">
                                check_box
                            </span><br />
                            Common Prospectus
                        </div>
                    </a>
                    <a class="col-4 btn" onClick={()=>{showWebContent('http://egyankosh.ac.in/')}}  >
                        <div class="p-3 border rounded bg-white shadow text-center h6">
                            <span class="material-symbols-outlined">
                                check_box
                            </span><br />
                            e-Gyan Kosh
                        </div>
                    </a>
                    <a class="col-4 btn" onClick={()=>{showWebContent('https://webservices.ignou.ac.in/assignments/')}}  >
                        <div class="p-3 border rounded bg-white shadow text-center h6">
                            <span class="material-symbols-outlined">
                                check_box
                            </span><br />
                            Latest Assignments
                        </div>
                    </a>

                </div>

                <div class="row mt-2">
                    <a class="col-4 btn" onClick={()=>{showWebContent('https://admission.ignou.ac.in/changeadmdata/admissionstatusnew.asp')}}  >
                        <div class="p-3 border rounded bg-white shadow text-center h6">
                            <span class="material-symbols-outlined">
                                menu_book
                            </span><br />
                            Admission Status
                        </div>
                    </a>
                    <a class="col-4 btn" onClick={()=>{showWebContent('https://webservices.ignou.ac.in/Pre-Question/')}}  >
                        <div class="p-3 border rounded bg-white shadow text-center h6">
                            <span class="material-symbols-outlined">
                                menu_book
                            </span><br />
                            Previous Papers
                        </div>
                    </a>
                    <a class="col-4 btn" onClick={()=>{showWebContent('https://onlinerr.ignou.ac.in/')}}  >
                        <div class="p-3 border rounded bg-white shadow h6">
                            <span class="material-symbols-outlined">
                                star
                            </span><br />
                            Re Registration
                        </div>
                    </a>
                </div>

                <div className='row mt-2'>
                    <a class="col-4 btn" onClick={()=>{showWebContent('http://www.ignou.ac.in/ignou/aboutignou/division/sed/datesheet')}}  >
                        <div class="p-3 border rounded bg-white shadow text-center h6">
                            <span class="material-symbols-outlined">
                                check_box
                            </span><br />
                            Datesheet
                        </div>
                    </a>
                    <a class="col-4 btn" onClick={()=>{showWebContent('http://www.ignou.ac.in/ignou/studentzone/results/2')}}  >
                        <div class="p-3 border rounded bg-white shadow h6">
                            <span class="material-symbols-outlined">
                                star
                            </span><br />
                            Results
                        </div>
                    </a>
                    <a class="col-4 btn" onClick={()=>{showWebContent('http://www.ignou.ac.in/ignou/studentzone/results/5')}}  >
                        <div class="p-3 border rounded bg-white shadow h6">
                            <span class="material-symbols-outlined">
                                star
                            </span><br />
                            Revaluation Result
                        </div>
                    </a>
                    
                </div>

                <div className='row mt-2'>
                    <a class="col-4 btn" onClick={()=>{showWebContent('https://epgp.inflibnet.ac.in/Home/Download#')}}  >
                        <div class="p-3 border rounded bg-white shadow h6">
                            <span class="material-symbols-outlined">
                                star
                            </span><br />
                            e-PG Phathsala
                        </div>
                    </a>
                    <a class="col-4 btn" onClick={()=>{showWebContent('https://open.umn.edu/opentextbooks/')}}  >
                        <div class="p-3 border rounded bg-white shadow h6">
                            <span class="material-symbols-outlined">
                                star
                            </span><br />
                            Open Textbook Library
                        </div>
                    </a>
                    <a class="col-4 btn" onClick={()=>{showWebContent('https://ndl.iitkgp.ac.in/')}}  >
                        <div class="p-3 border rounded bg-white shadow h6">
                            <span class="material-symbols-outlined">
                                star
                            </span><br />
                            National Digital Library
                        </div>
                    </a>
                    
                </div>

                <div class="row gx-3 mt-2">
                    <a class="col-4 btn" onClick={()=>{showWebContent('https://exam.ignou.ac.in/')}}  >
                        <div class="p-3 border rounded bg-white shadow  text-center h6">
                            <span class="material-symbols-outlined">
                                laptop_chromebook
                            </span><br />
                            Online Exams
                        </div>
                    </a>
                    <a class="col-4 btn" onClick={()=>{showWebContent('https://webservices.ignou.ac.in/assignments/')}} >
                        <div class="p-3 border rounded bg-white shadow text-center h6">
                            <span class="material-symbols-outlined">
                                library_books
                            </span><br />
                            Library Books
                        </div>
                    </a>
                    <a class="col-4 btn" onClick={()=>{showWebContent('https://webservices.ignou.ac.in/assignments/')}}  >
                        <div class="p-3 border rounded bg-white shadow h6">
                            <span class="material-symbols-outlined">
                                star
                            </span><br />
                            Assign <br /> work
                        </div>
                    </a>
                
                </div>

                <div className='row mt-2'>
                    <a class="col-4 btn" onClick={()=>{showWebContent('https://admission.ignou.ac.in/changeadmdata/AdmissionStatusNew.asp')}}  >
                        <div class="p-3 border rounded bg-white shadow text-center h6">
                            <span class="material-symbols-outlined">
                                how_to_reg
                            </span><br />
                            Register Details
                        </div>
                    </a>
                </div>
            </div>


            <span className='mb-5'>
                <br />
                <br />
            </span>
        </>
    )
}


function showWebContent(url){
    if(url != null){
        window.Android.showWebContent(url)
    }
  }
  
  // onClick={()=>{showWebContent()}} 
