import React from 'react'
import { Link } from 'react-router-dom'

export default function Options() {
    return (


        <>
            <div className='p-3 m-1 mt-2 rounded ' id='options'>
                <b className='p-3 opacity-75'>Important Links</b><br />
                <br />


                {/* Block One */}
                <div class="row mt-2">
                    <a class="col-4 btn" onClick={() => { openNotification() }}>
                        <div class="p-3 border rounded bg-white shadow text-center h6">
                            <img width="64" height="64" src="https://img.icons8.com/nolan/64/00C6FF/0072FF/push-notifications.png" alt="push-notifications" /><br />
                            Latest
                            <br />
                            Notice
                        </div>
                    </a>

                    <a class="col-4 btn" onClick={() => { showWebContent('https://ignouadmission.samarth.edu.in') }}>
                        <div class="p-3 border rounded bg-white shadow text-center h6">
                            <img width="64" height="64" src="https://img.icons8.com/nolan/64/00C6FF/0072FF/university.png" alt="university" /><br />
                            Join
                            <br />
                            IGNOU
                        </div>
                    </a>

                    <a class="col-4 btn" onClick={() => { showWebContent('https://admission.ignou.ac.in/changeadmdata/AdmissionStatusNew.asp') }}>
                        <div class="p-3 border rounded bg-white shadow text-center h6">
                            <img width="64" height="64" src="https://img.icons8.com/nolan/64/00C6FF/0072FF/bulleted-list.png" alt="bulleted-list" /><br />
                            Register
                            <br />
                            Details
                        </div>
                    </a>
                </div>

                {/* Block Two */}





                <div class="row mt-2">
                    <a class="col-4 btn" onClick={() => { showWebContent('https://ignouadmission.samarth.edu.in/') }}>
                        <div class="p-3 border rounded bg-white shadow text-center h6">


                            <img width="64" height="64" src="https://img.icons8.com/nolan/64/00C6FF/0072FF/security-pass.png" alt="security-pass" />
                            <br />

                            Download ID Card
                        </div>
                    </a>


                    <a class="col-4 btn" onClick={() => { showWebContent('https://webservices.ignou.ac.in/assignments') }}>
                        <div class="p-3 border rounded bg-white shadow h6">

                            <img width="64" height="64" src="https://img.icons8.com/nolan/64/00C6FF/0072FF/task.png" alt="task" /><br />
                            Syllabus
                        </div>

                    </a>


                    <a class="col-4 btn" onClick={() => { showWebContent('https://webservices.ignou.ac.in/assignments') }}>
                        <div class="p-3 border rounded bg-white shadow h6">

                            <img width="64" height="64" src="https://img.icons8.com/nolan/64/00C6FF/0072FF/task.png" alt="task" /><br />
                            e-Content
                        </div>

                    </a>



                </div>




                {/* Block Three */}



                <Link class="col-4 btn" to='/study'>
                    <div class="p-3 border rounded bg-white shadow text-center h6">
                        <img width="64" height="64" src="https://img.icons8.com/nolan/64/00C6FF/0072FF/saving-book.png" alt="saving-book" /><br />
                        Study <br /> Material
                    </div>
                </Link>


                <a class="col-4 btn" onClick={() => { showWebContent('https://webservices.ignou.ac.in/assignments') }}>
                    <div class="p-3 border rounded bg-white shadow h6">

                        <img width="64" height="64" src="https://img.icons8.com/nolan/64/00C6FF/0072FF/task.png" alt="task" /><br />
                        Assignment
                        <br />
                        work
                    </div>

                </a>


                <a class="col-4 btn" onClick={() => { showWebContent('https://ignouadmission.samarth.edu.in/') }}>
                    <div class="p-3 border rounded bg-white shadow text-center h6">


                        <img width="64" height="64" src="https://img.icons8.com/nolan/64/00C6FF/0072FF/security-pass.png" alt="security-pass" />
                        <br />

                        Download ID Card
                    </div>
                </a>


            </div>


            {/* Block Four */}


            <div class="row mt-2">
                <a class="col-4 btn" onClick={() => { showWebContent('https://gradecard.ignou.ac.in/gradecard') }}>
                    <div class="p-3 border rounded bg-white shadow text-center h6">
                        <img width="64" height="64" src="https://img.icons8.com/nolan/64/00C6FF/0072FF/report-card.png" alt="report-card" /><br />
                        Grade Card
                    </div>
                </a>
                <a class="col-4 btn" onClick={() => { showWebContent('https://webservices.ignou.ac.in/assignments') }}>
                    <div class="p-3 border rounded bg-white shadow h6">

                        <img width="64" height="64" src="https://img.icons8.com/nolan/64/00C6FF/0072FF/task.png" alt="task" /><br />
                        Assignment
                        <br />
                        work
                    </div>

                </a>


                <a class="col-4 btn" onClick={() => { showWebContent('https://ignouadmission.samarth.edu.in/') }}>
                    <div class="p-3 border rounded bg-white shadow text-center h6">


                        <img width="64" height="64" src="https://img.icons8.com/nolan/64/00C6FF/0072FF/security-pass.png" alt="security-pass" />
                        <br />

                        Download ID Card
                    </div>
                </a>


            </div>



            <div class="row mt-2">




                <a class="col-4 btn" onClick={() => { showWebContent('https://docs.google.com/forms/d/e/1FAIpQLSdUsk5jZ22Fg4Ex2UCbME7k-mophfPK4pQTQ56WlUKTVDPSJg/viewform?usp=sf_link') }}>
                    <div class="p-3 border rounded bg-white shadow text-center h6">
                        <img width="64" height="64" src="https://img.icons8.com/nolan/64/00C6FF/0072FF/why-us-male.png" alt="why-us-male" /><br />
                        Suggestions / Query
                    </div>
                </a>

            </div>

        


            <span className='mb-5'>
                <br />
                <br />
            </span>
        </>
    )
}


function showWebContent(url) {
    if (url != null) {
        window.Android.showWebContent(url)
    }
}

function openNotification() {
    window.Android.openNotification();
}