import React from 'react'

export default function ContactUs() {
    return (
        <div>
            <body>
             
            <>
  <h1>Contact Us !</h1>
  <h2 style={{ textAlign: "center" }}>
    Welcome to <span id="W_Name"> ignouplus.com</span>!
  </h2>
  <p style={{ fontSize: 17 }}>
    Please email us if you have any queries about the site, advertising, or
    anything else.
  </p>
  <div style={{ textAlign: "center" }}>
    <img
      alt="contact-us"
      src="https://lh3.googleusercontent.com/-BA7qy8h_v1g/YLVCWDNZdCI/AAAAAAAAALw/rsHNJWX0BK4P5CuB0ymG8QkJ9A9E8KchgCLcBGAsYHQ/w320-h87/email-us-1805514__480.webp"
      width={320}
      height={87}
    />
    <p style={{ marginLeft: "25%" }}>
      <i
        className="fas fa-envelope-open-text"
        style={{ color: "#2c3e50", fontSize: 20 }}
      />{" "}
      <b>
        <i>
          {" "}
          <span id="W_Email">
            <a href="mailto:prasharaman111@gmail.com">
              prasharaman111@gmail.com
            </a>
          </span>
        </i>
      </b>
      <br />
      <i
        className="fab fa-whatsapp-square"
        style={{ color: "#3edc81", fontSize: 20 }}
      />{" "}
      <b>
        <span id="W_whatsapp">
          <a href="tel:7018717071">7018717071</a>
        </span>
      </b>
      <br />
    </p>
    <h3 style={{ color: "#3e005d" }}>
      We will revert you as soon as possible...!
    </h3>
    <p style={{ color: "#3e005d", textAlign: "center" }}>
      Thank you for contacting us! <br />
      <b>Have a great day</b>
    </p>
    <span style={{ fontSize: 1, opacity: 0 }}>
      This page is generated with the help of{" "}
      <a
        href="https://www.blogearns.com/2021/06/free-contact-us-page-generator.html"
        style={{ color: "inherit" }}
      >
        Contact Us Page Generator
      </a>
    </span>
  </div>
</>
   

            </body>
        </div>
    )
}
