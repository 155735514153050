import React from 'react'
import {Link } from 'react-router-dom'
import '../components-css/BottomBar.css'
import StudentZone from '../pages/StudentZone'
import Study from '../pages/Study'


export default function BottomBar(props) {
  return (
        <div id='bottombar'>
            <div className='row fw-bold'>
                



                {props.highlightHome ?
                    <Link className='btn col-3 text-small-bottom text-primary'>
                            <img width="30" height="30" src="https://img.icons8.com/material-outlined/24/228BE6/home--v2.png" alt="home--v2"/>
                            <br />
                            <b>
                            HOME
                            </b>
                    </Link>  
                :
                    <Link className='btn col-3 text-small-bottom text-secandory' to="/">
                        {/* <span class="material-symbols-outlined opacity-75 fw-bold fs-2">
                            home
                        </span> */}
                        <img width="24" height="24" src="https://img.icons8.com/material-outlined/48/home--v2.png" alt="home--v2"/>
                        <br />
                        <b>
                            HOME    
                        </b>
                    </Link>  
                }

                {props.highlightStudy ?
                <Link className='btn col-3 text-small-bottom text-primary'>
                    {/* <span class="material-symbols-outlined opacity-75 fw-bold fs-2">
                        lab_profile
                    </span> */}
                    <img width="30" height="30" src="https://img.icons8.com/material-outlined/24/228BE6/saving-book.png" alt="saving-book"/>
                    <br />
                    <b>
                        STUDY
                    </b>
                </Link>
                :
                <Link className='btn col-3 text-small-bottom text-secandory' to="/study">
                    {/* <span class="material-symbols-outlined opacity-75 fw-bold fs-2">
                        lab_profile
                    </span> */}
                    <img width="24" height="24" src="https://img.icons8.com/material-outlined/24/000000/saving-book.png" alt="saving-book"/>
                    <br />
                    STUDY
                </Link>
                }

                {props.highlightShop ?
                <Link className='btn col-3 text-small-bottom text-primary'>
                    {/* <span class="material-symbols-outlined opacity-75 fw-bold fs-2">
                    <span class="material-symbols-outlined">
                        shopping_cart
                    </span>
                    </span> */}
                    <img width="30" height="30" src="https://img.icons8.com/material-outlined/24/228BE6/shopping-cart-loaded.png" alt="shopping-cart-loaded"/>
                    <br />
                    <b>
                    SHOP
                    </b>
                    
                </Link>
                :
                <Link className='btn col-3 text-small-bottom text-secandory' to="/shop">
                    {/* <span class="material-symbols-outlined opacity-75 fw-bold fs-2">
                        shopping_cart
                    </span> */}
                    <img width="24" height="24" src="https://img.icons8.com/material-outlined/24/000000/shopping-cart-loaded.png" alt="shopping-cart-loaded"/>
                    <br />
                    <b>
                    SHOP
                    </b>
                    
                </Link>
                }

                {props.highlightStudentZone ?
                <Link className='btn col-3 text-small-bottom text-primary' to="/student-zone">
                    {/* <span class="material-symbols-outlined opacity-75 fw-bold fs-2">
                        model_training
                    </span> */}
                    <img width="30" height="30" src="https://img.icons8.com/material-outlined/24/228BE6/student-female.png" alt="student-female"/>
                    <br />
                    <b>
                    Student Zone
                    </b>
                </Link>
                :
                <Link className='btn col-3 text-small-bottom text-secandory' to="/student-zone">
                    {/* <span class="material-symbols-outlined opacity-75 fw-bold fs-2">
                        model_training
                    </span> */}
                    <img width="24" height="24" src="https://img.icons8.com/material-outlined/24/000000/student-female.png" alt="student-female"/>
                    <br />
                    <b>
                    STUDENT ZONE
                    </b>
                </Link>
                }
            </div>
        </div>
  )
}
