import React from 'react'
import {Carousel} from 'react-bootstrap'
import imgLink1 from '../assets/img1.jpg'
import imgLink2 from '../assets/img2.jpg'
import imgLink3 from '../assets/img3.png'


export default function ShopCarousel() {
    


    
    return (
        
        <>
            <Carousel className='m-1'>
                <Carousel.Item>
                    <img src={imgLink1} class="d-block w-100" alt="..." height="200px" />
                </Carousel.Item>
                <Carousel.Item>
                    <img src={imgLink2} class="d-block w-100" alt="..." height="200px" />
                </Carousel.Item>
                <Carousel.Item>
                    <img src={imgLink3} class="d-block w-100" alt="..." height="200px" />
                </Carousel.Item>
            </Carousel>
        </>
    )
}
