import React from 'react'
import ShopCarousel from '../components-shop/ShopCarousel'
import BottomBar from '../components/BottomBar'
import logo from '../logo.svg'

export default function Shop() {
  return (
    <div>
      <ShopCarousel />

      <div className='container mt-5'>
          <span className='shadow row m-1 p-3'>
            <img className='col-2' src={logo} />
            <span className='col-8 text-wrap h1'>Assignments</span>
            <a className='col-2 btn bg-success text-light' onClick={()=>{showWebContent('https://ignouking.com/assignment-store/')}} >Buy</a>
          </span>
          <span className='shadow row m-1 p-3'>
            <img className='col-2' src={logo} width='80px' />
            <span className='col-8 text-wrap h1'>Solved Paper</span>
            <button className='col-2 btn bg-success text-light'>Buy</button>
          </span>
          <span className='shadow row m-1 p-3'>
            <img className='col-2' src={logo} width='80px' />
            <span className='col-8 text-wrap h1'>Study Material</span>
            <a className='col-2 btn bg-success text-light' onClick={()=>{showWebContent('https://www.gullybaba.com/ignou-help-books/')}} >Buy</a>
          </span>
      </div>





      <BottomBar highlightShop="true" />

    </div>
  )
}


function showWebContent(url){
  if(url != null){
      window.Android.showWebContent(url)
  }
}

// onClick={()=>{showWebContent()}} 
