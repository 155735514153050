import React from 'react'
import BottomBar from '../components/BottomBar'
import StudentZoneOptions from '../components-student-zone/StudentZoneOptions'
import StudentZoneCarousel from '../components-student-zone/StudentZoneCarousel'

export default function StudentZone() {
  return (
    <>
      <StudentZoneCarousel />
      <StudentZoneOptions />
      <BottomBar highlightStudentZone="true"/>
    </>
  )
}
