import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import NavBar from './components/NavBar'
import SearchBar from './components/SearchBar'
import Home from './pages/Home'
import Study from './pages/Study'
import Shop from './pages/Shop'
import StudentZone from './pages/StudentZone'

import PageNotFound from './pages/PageNotFound'
import PrivacyPolicy from './pages/PrivacyPolicy'
import AboutUs from './pages/AboutUs'
import Disclaimer from './pages/Disclaimer'
import ContactUs from './pages/ContactUs'

export default function App() {
  return (
    <>
      {/* <NavBar />
      <SearchBar /> */}
      <BrowserRouter>
        <Routes>


          <Route path='/' element={<Home />}></Route>
          <Route path='/study' element={<Study />}></Route>
          <Route path='/shop' element={<Shop />}></Route>
          <Route path='/student-zone' element={<StudentZone />}></Route>
          <Route path='/privacy-policy' element={<PrivacyPolicy />}></Route>
          <Route path='/about-us' element={<AboutUs />}></Route>
          <Route path='/disclaimer' element={<Disclaimer />}></Route>
          <Route path='/contact-us' element={<ContactUs />}></Route>
          

          <Route path='*' element={<PageNotFound />}></Route>


        </Routes>
      </BrowserRouter>


    </>
  )
}
