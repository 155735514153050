import React from 'react'
import BottomBar from '../components/BottomBar'
import StudyOptions from '../components-study/StudyOptions'
import StudyCarousel from '../components-study/StudyCarousel'

export default function Study() {
  return (
    <div>
    
<StudyCarousel />
<StudyOptions />

<hr />




<BottomBar highlightStudy="true"/>
    </div>
  )
}
