import React from 'react'
import ScrollableNotification from '../components/ScrollableNotification'
import NotificationSection from '../components/NotificationSection'
import Options from '../components/Options'
import BottomBar from '../components/BottomBar'

export default function Home() {
  return (
    <div>

    <ScrollableNotification />
    <NotificationSection />
    <Options />
    
    <BottomBar highlightHome="true"/>

    
    </div>
  )
}
