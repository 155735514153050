import React from 'react'

export default function ScrollableNotification() {
  return (
    <div>
        <div id='notificationBar' className='border p-1'>
            <marquee width="100%" direction="left" scrollamount="5">

                <a href='https://exam.ignou.ac.in/EXAMFORMJUN22_COE'> Submission of Online examination form for June 2022 of IGNOU Online Programmes</a>

                <a href='https://exam.ignou.ac.in/EXAMFORMJUN22_COE'> Submission of Online examination form for June 2022 of IGNOU Online Programmes</a>


            </marquee>
        </div>
    </div>
  )
}
