import { Carousel } from 'react-bootstrap'
import React from 'react'
import imgLink1 from '../assets/digitalDegree.jpg'
import imgLink2 from '../assets/resultDec.jpg'
import imgLink3 from '../assets/resultUpdate.jpg'

export default function NotificationSection() {
  
  return (
    <>



    <Carousel className='m-1'>
    <Carousel.Item>
      <img src={imgLink1} class="d-block w-100" alt="..." height="200px"/>
      </Carousel.Item>
      <Carousel.Item>
      <img src={imgLink2} class="d-block w-100" alt="..." height="200px"/>
      </Carousel.Item>
      <Carousel.Item>
      <img src={imgLink3} class="d-block w-100" alt="..." height="200px"/>
      </Carousel.Item>
    </Carousel>



    </>
  )
}
