import React from 'react'

export default function StudyOptions() {
    return (

        <>
            <div className='p-3 m-1 mt-2 rounded ' id='options'>
                <b className='p-3 opacity-75'>Important Links</b><br />
                <br />


                <div class="row mt-2">
                    <a class="col-4 btn" onClick={() => { showWebContent("https://webservices.ignou.ac.in/assignments/") }}>
                        <div class="p-3 border rounded bg-white shadow text-center h6">
                            <img width="64" height="64" src="https://img.icons8.com/nolan/64/00C6FF/0072FF/task.png" alt="task" /><br />
                            Assignment Work
                        </div>
                    </a>
                    <a class="col-4 btn" onClick={() => { showWebContent('https://egyankosh.ac.in/') }} >
                        <div class="p-3 border rounded bg-white shadow text-center h6">
                        <img width="64" height="64" src="https://img.icons8.com/nolan/64/00C6FF/0072FF/saving-book.png" alt="saving-book" /><br />
                            e-Gyan <br /> Kosh
                        </div>
                    </a>
                    <a class="col-4 btn" onClick={() => { showWebContent('https://webservices.ignou.ac.in/Pre-Question/') }} >
                        <div class="p-3 border rounded bg-white shadow text-center h6">
                        <img width="64" height="64" src="https://img.icons8.com/nolan/64/00C6FF/0072FF/questions.png" alt="questions"/><br />
                            Question Papers
                        </div>
                    </a>
                </div>


                <div class="row gx-3 mt-2">
                    <a class="col-4 btn" onClick={() => { showWebContent('exam.ignou.ac.in/') }} >
                        <div class="p-3 border rounded bg-white shadow  text-center h6">
                        <img width="64" height="64" src="https://img.icons8.com/nolan/64/00C6FF/0072FF/working-with-a-laptop.png" alt="working-with-a-laptop"/><br />
                            Online <br /> Exams
                        </div>
                    </a>
                    <a class="col-4 btn" onClick={() => { showWebContent('https://admission.ignou.ac.in/changeadmdata/AdmissionStatusNew.asp') }} >
                        <div class="p-3 border rounded bg-white shadow text-center h6">
                        <img width="64" height="64" src="https://img.icons8.com/nolan/64/00C6FF/0072FF/about-us-male.png" alt="about-us-male"/><br />
                            Register <br /> Details
                        </div>
                    </a>
                    <a class="col-4 btn" onClick={() => { showWebContent('https://epgp.inflibnet.ac.in/Home/Download#') }} >
                        <div class="p-3 border rounded bg-white shadow h6">
                        <img width="64" height="64" src="https://img.icons8.com/nolan/64/00C6FF/0072FF/book-stack.png" alt="book-stack"/><br />
                            e-PG Phathsala
                        </div>
                    </a>
                </div>

                <div className='row mt-2'>
                    <a class="col-4 btn">
                        <div class="p-3 border rounded bg-white shadow text-center h6">
                        <img width="64" height="64" src="https://img.icons8.com/nolan/64/00C6FF/0072FF/book-shelf.png" alt="book-shelf"/><br />
                            Library Books
                        </div>
                    </a>
                    <a class="col-4 btn" onClick={() => { showWebContent('https://ndl.iitkgp.ac.in/') }} >
                        <div class="p-3 border rounded bg-white shadow h6">
                        <img width="64" height="64" src="https://img.icons8.com/nolan/64/00C6FF/0072FF/book-shelf.png" alt="book-shelf"/><br />
                            National Digital Library
                        </div>
                    </a>
                    <a class="col-4 btn" onClick={() => { showWebContent('https://open.umn.edu/opentextbooks/') }} >
                        <div class="p-3 border rounded bg-white shadow h6">
                        <img width="64" height="64" src="https://img.icons8.com/nolan/64/00C6FF/0072FF/book-shelf.png" alt="book-shelf"/><br />
                            Open Textbook Library
                        </div>
                    </a>
                </div>

            </div>


            <span className='mb-5'>
                <br />
                <br />
            </span>
        </>
    )
}


function showWebContent(url) {
    if (url != null) {
        window.Android.showWebContent(url)
    }
}

// onClick={()=>{showWebContent()}} 